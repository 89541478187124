



























































































































































































































































































.itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}

.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;

  .select-header-1 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    width: 150px;
  }

  .select-header-2 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    margin-left: 50px;
    width: 250px;
  }
}
